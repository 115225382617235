import React from "react";
import {
  SiteNavigationElementSchema,
  FoodEstablishmentSchema,
  NavigationV2,
  LocationSelect,
  HookComponentProvider,
  HoursOfOperation,
  LocationsContext,
  RecaptchaProvider,
  FishermanBanner,
} from "@bluefin/components";
import { Header } from "semantic-ui-react";
import { useContext } from "react";
import { graphql, StaticQuery } from "gatsby";

import {
  getComponentFiles,
  filterRegularLinks,
  filterButtonLinks,
} from "../utils/utils";
import "semantic-ui-less/semantic.less";
import "../style/WebsiteLayout.css";

export default ({ children }) => (
  <StaticQuery
    query={graphql`
      query {
        allFishermanBusinessWebsitePage(
          sort: { order: ASC, fields: navigationPriority }
        ) {
          nodes {
            slug
            description
            url
            pageType
            title
            navigationPriority
            groupName
            props
          }
        }
        fishermanBusiness {
          name
          type
          menu {
            schedules {
              availableDays
              categories
              _id
              name
            }
            categories {
              items
              name
              _id
            }
            items {
              modifierSets
              description
              _id
              name
              variations {
                _id
                menuItem
                name
                order
                price
              }
            }
            modifierSets {
              _id
              minAllowed
              modifiers
              name
            }
            modifiers {
              _id
              name
              price
            }
          }
        }
        allFishermanBusinessLocation {
          nodes {
            street
            city
            state
            zipCode
            country
            email
            phoneNumber
            name
            hours {
              open
              close
              day
            }
          }
        }
        fishermanBusinessWebsitePage(pageType: { eq: "Home" }) {
          components {
            fastId
            componentIdentifier
            order
            files {
              file
              gatsbyFile {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH)
                }
              }
            }
          }
        }
      }
    `}
    render={(data) => {
      const {
        allFishermanBusinessWebsitePage,
        fishermanBusiness,
        allFishermanBusinessLocation,
        fishermanBusinessWebsitePage,
      } = data;

      return (
        <React.Fragment>
          <SiteNavigationElementSchema
            pages={allFishermanBusinessWebsitePage.nodes}
            siteUrl={"https://www.brewskis-mr-liquor.com"}
          />
          <FoodEstablishmentSchema
            name={fishermanBusiness.name}
            siteUrl={"https://www.brewskis-mr-liquor.com"}
            businessType={fishermanBusiness.type}
            address={allFishermanBusinessLocation.nodes}
            menu={fishermanBusiness.menu}
            images={getComponentFiles({
              components: fishermanBusinessWebsitePage.components,
              componentName: "HeroElement",
              numToSelect: 1,
            })}
          />
          <div className={"site-container"}>
            <React.Fragment>
              <NavigationV2
                sticky={true}
                fullScreen={true}
                desktop={
                  <NavigationV2.Container>
                    <NavigationV2.Menu className={"internal-links"}>
                      <NavigationV2.Item>
                        <NavigationV2.Links
                          links={filterRegularLinks({
                            links: allFishermanBusinessWebsitePage.nodes,
                          })}
                        />
                      </NavigationV2.Item>
                    </NavigationV2.Menu>
                    <NavigationV2.Menu className={"logo"}>
                      <NavigationV2.Logo
                        title={
                          <Header as={"h3"}>{fishermanBusiness.name}</Header>
                        }
                      />
                    </NavigationV2.Menu>
                    <NavigationV2.Menu className={"external-links"}>
                      <NavigationV2.Item>
                        <NavigationV2.Links
                          dropdownDirection={"left"}
                          links={filterButtonLinks({
                            links: allFishermanBusinessWebsitePage.nodes,
                          })}
                        />
                      </NavigationV2.Item>
                    </NavigationV2.Menu>
                  </NavigationV2.Container>
                }
                mobile={
                  <React.Fragment>
                    <NavigationV2.Container className={"mobile-header"}>
                      <NavigationV2.Toggle />
                      <NavigationV2.Menu className={"logo"}>
                        <NavigationV2.Logo
                          title={
                            <Header as={"h3"}>{fishermanBusiness.name}</Header>
                          }
                        />
                      </NavigationV2.Menu>
                    </NavigationV2.Container>
                    <NavigationV2.Container tray={true} vertical={true}>
                      <NavigationV2.Menu className={"internal-links"}>
                        <NavigationV2.Item>
                          <NavigationV2.Links
                            links={filterRegularLinks({
                              links: allFishermanBusinessWebsitePage.nodes,
                            })}
                          />
                        </NavigationV2.Item>
                      </NavigationV2.Menu>
                      <NavigationV2.Menu className={"external-links"}>
                        <NavigationV2.Item className={"location-select-item"}>
                          <label>Location:</label>
                          <LocationSelect useContext={true} search={false} />
                        </NavigationV2.Item>
                        <NavigationV2.Item className={"hours"}>
                          <HookComponentProvider
                            hook={useContext}
                            component={
                              <HoursOfOperation
                                displayOption={"dailyWithPopup"}
                                displayOpenClosedStatus={true}
                              />
                            }
                            hookToPropsMap={{
                              hours: "activeLocation.hours",
                              timezone: "activeLocation.timezone",
                            }}
                            hookParams={[LocationsContext]}
                            onlyRenderOnClientSide={true}
                          />
                        </NavigationV2.Item>
                        <NavigationV2.Item>
                          <NavigationV2.Links
                            links={filterButtonLinks({
                              links: allFishermanBusinessWebsitePage.nodes,
                            })}
                          />
                        </NavigationV2.Item>
                      </NavigationV2.Menu>
                    </NavigationV2.Container>
                  </React.Fragment>
                }
              />
            </React.Fragment>
            <RecaptchaProvider>
              <div className={"page-container"}>{children}</div>
            </RecaptchaProvider>
          </div>
          <FishermanBanner />
        </React.Fragment>
      );
    }}
  />
);
